import Vue from 'vue';

// Constants moved to top level for reusability
const SEO_CONSTANTS = {
    DEFAULT_TITLE: 'Sports Schedules',
    TITLE_POSTFIX: ' | DIRECTV',
    DEFAULT_DESCRIPTION: 'Sports Schedules for NFL, Soccer, NHL, NBA, World Cup, MLB, Cricket, MLS, NCAA, All Sports',
    DEFAULT_KEYWORDS: 'NFL, Soccer, Sports Schedule, Sports, NHL, NBA, World Cup, MLB, Cricket, Leagues, MLS, NCAA, All Sports',
    ROUTE_SETTINGS: window.initialState?.routes || {}
};

// Helper functions
function capitalizeFirstLetter(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : '';
}

function replaceTemplateStrings(text, data) {
    const { sport, league, leagueTitle, leagueTitleFull, team } = data;

    return text
        .replace(/%SPORT%/g, sport || '')
        .replace(/%LEAGUE%/g, leagueTitle || '')
        .replace(/%LEAGUE_FULL%/g, leagueTitleFull || '')
        .replace(/%TEAM%/g, team || '')
        .replace(/%SPORT_OR_LEAGUE%/g, sport && !league ? sport : leagueTitle || '')
        .replace(/%YEAR%/g, new Date().getFullYear().toString());
}

// Main SEO generation logic
export function generateSEOTags(route, sportsData, isDebug = false) {
    const { DEFAULT_TITLE, TITLE_POSTFIX, DEFAULT_DESCRIPTION, DEFAULT_KEYWORDS, ROUTE_SETTINGS } = SEO_CONSTANTS;

    // Initialize variables
    let routeSport = route.params.sport?.toLowerCase() || false;
    let routeLeague = route.params.league?.toLowerCase() || false;
    let routeTeam = false;

    let leagueTitle = false;
    let leagueTitleFull = false;
    let leagueDescription = false;
    let leagueKeywords = false;

    // Process team parameter if it exists
    if (route.params.team) {
        routeTeam = route.params.team.toUpperCase();
        const teamNameParts = routeTeam.split('-');
        routeTeam = teamNameParts.slice(0, -1).join(' '); // Removes the ID part
    }

    // Process league and sport combinations
    if (routeLeague) {
        if (routeSport === 'all' && routeLeague === 'all') {
            leagueTitle = 'All';
            leagueTitleFull = 'All';
        } else if (routeSport === 'custom' || routeLeague === 'custom') {
            leagueTitle = routeSport !== 'all' && routeSport !== 'custom'
                ? `Custom ${capitalizeFirstLetter(routeSport)}`
                : 'Custom';
            leagueTitleFull = leagueTitle;
        } else if (routeSport !== 'all' && routeSport && routeLeague === 'all') {
            leagueTitle = capitalizeFirstLetter(routeSport);
            leagueTitleFull = leagueTitle;
        } else if (routeLeague !== 'all' && routeLeague !== 'custom') {
            // Find league in sportsData
            const league = sportsData?.reduce((found, sport) =>
                sport.leagues?.find(l => l.slug.toLowerCase() === routeLeague.toLowerCase()) || found, null);

            if (league) {
                leagueTitle = league.show_sport_in_title
                    ? `${league.title} ${league.sport?.title || ''}`
                    : league.title;
                leagueTitleFull = leagueTitle;
                leagueDescription = league.description || `Find ${leagueTitle} TV channel and networks on DIRECTV to watch your favorite teams play.`;
                leagueKeywords = league.keywords || DEFAULT_KEYWORDS;
            }
        }
    }

    // Get route settings
    const routeName = route.name || 'home';
    const routeSetting = ROUTE_SETTINGS[routeName] || ROUTE_SETTINGS['home'];

    // Initialize final values
    let finalTitle = routeSetting.title || DEFAULT_TITLE;
    let finalDescription = leagueDescription || routeSetting.description || DEFAULT_DESCRIPTION;
    let finalKeywords = leagueKeywords || routeSetting.keywords || DEFAULT_KEYWORDS;

    // Replace template strings
    const templateData = {
        sport: capitalizeFirstLetter(routeSport),
        league: routeLeague,
        leagueTitle,
        leagueTitleFull,
        team: routeTeam
    };

    finalTitle = replaceTemplateStrings(finalTitle, templateData);
    finalDescription = replaceTemplateStrings(finalDescription, templateData);
    finalKeywords = replaceTemplateStrings(finalKeywords, templateData);

    // Add title postfix
    finalTitle += TITLE_POSTFIX;

    return {
        title: finalTitle,
        description: finalDescription,
        keywords: finalKeywords
    };
}

// Updated afterEachHook
export default function afterEachHook(to, from, next) {
    console.log("Router after each");

    Vue.nextTick(() => {
        const sportsData = window.initialState?.sports;
        const seoTags = generateSEOTags(to, sportsData);

        // Update document tags
        document.title = seoTags.title;
        document.querySelector('meta[name="og:title"]').setAttribute("content", seoTags.title);
        document.querySelector('meta[name="twitter:title"]').setAttribute("content", seoTags.title);

        document.querySelector('meta[name="description"]').setAttribute("content", seoTags.description);
        document.querySelector('meta[name="og:description"]').setAttribute("content", seoTags.description);
        document.querySelector('meta[name="twitter:description"]').setAttribute("content", seoTags.description);

        document.querySelector('meta[name="keywords"]').setAttribute("content", seoTags.keywords);
    });
}

// Test function for debugging SEO tags
export function testSEOTags(routes, sportsData) {
    const results = routes.map(route => {
        const seoTags = generateSEOTags(route, sportsData, true);
        return {
            route: `${route.name} ${JSON.stringify(route.params)}`,
            ...seoTags
        };
    });

    console.table(results);
    return results;
}

// Example test routes covering all scenarios
const testRoutes = [
    // Home and basic routes
    { name: 'home', params: {} },
    { name: 'home_params', params: { sport: 'all', league: 'all' } },
    { name: 'home_params', params: { sport: 'all', league: 'custom' } },

    { name: 'home_params', params: { sport: 'custom', league: 'custom' } },

    { name: 'home_params', params: { sport: 'football', league: 'all' } },
    { name: 'home_params', params: { sport: 'football', league: 'NFL' } },

    { name: 'home_params', params: { sport: 'basketball', league: 'all' } },
    { name: 'home_params', params: { sport: 'basketball', league: 'NBA' } },

    { name: 'home_params', params: { sport: 'baseball', league: 'all' } },
    { name: 'home_params', params: { sport: 'baseball', league: 'MLB' } },


    // Sport + League combinations
    { name: 'league-schedule', params: { league: 'NFL' } },
    { name: 'league-schedule', params: { league: 'NBA' } },
    { name: 'league-schedule', params: { league: 'MLB' } },

    { name: 'standings', params: { league: 'NFL' } },
    { name: 'standings', params: { league: 'NBA' } },
    { name: 'standings', params: { league: 'MLB' } },

    { name: 'coaches', params: { league: 'NFL' } },
    { name: 'coaches', params: { league: 'NBA' } },
    { name: 'coaches', params: { league: 'MLB' } },

    // League only
    { name: 'teams', params: { league: 'NFL' } },
    { name: 'teams', params: { league: 'NBA' } },
    { name: 'teams', params: { league: 'MLB' } },

    // League + Team (no sport)
    { name: 'team-schedule', params: { league: 'NFL', team: 'SAN-FRANCISCO-49ERS-10' } },
    { name: 'team-schedule', params: { league: 'NBA', team: 'BOSTON-CELTICS-20' } },
    { name: 'team-schedule', params: { league: 'MLB', team: 'BOSTON-RED-SOX-40' } },


    { name: 'team-players', params: { league: 'NFL', team: 'SAN-FRANCISCO-49ERS-10' } },
    { name: 'team-players', params: { league: 'NBA', team: 'BOSTON-CELTICS-20' } },
    { name: 'team-players', params: { league: 'MLB', team: 'BOSTON-RED-SOX-40' } },

    // Static routes
    { name: 'embed', params: {} },
    { name: 'rsfee', params: {} },
    { name: 'rsinfo', params: {} }
];

// For development testing
// if (process.env.NODE_ENV === 'development') {
    //  testSEOTags(testRoutes, window.initialState?.sports);
// }
